// SMP routes
// @ts-ignore

// Articles
import ArticleListing from '../views/Resources/Articles/ArticleListing';
import ArticleCreate from '../views/Resources/Articles/ArticleCreate';
import ArticleEdit from '../views/Resources/Articles/ArticleEdit';

// Authors
import AuthorListing from '../views/Resources/Authors/AuthorListing';
import AuthorCreate from '../views/Resources/Authors/AuthorCreate';
import AuthorEdit from '../views/Resources/Authors/AuthorEdit';

// Banners
import BannerListing from '../views/Resources/Banners/BannerListing';
import BannerCreate from '../views/Resources/Banners/BannerCreate';
import BannerEdit from '../views/Resources/Banners/BannerEdit';

// Categories
import CategoryListing from '../views/Resources/Categories/CategoryListing';
import CategoryCreate from '../views/Resources/Categories/CategoryCreate';
import CategoryEdit from '../views/Resources/Categories/CategoryEdit';

// Galleries
import GalleryListing from '../views/Resources/Galleries/GalleryListing';
import GalleryCreate from '../views/Resources/Galleries/GalleryCreate';
import GalleryEdit from '../views/Resources/Galleries/GalleryEdit';

// Images
import ImageListing from '../views/Resources/Images/ImageListing';

// Playing Surface Images
import PlayingSurfaceImageListing from '../views/Resources/Images/playing-surface-image-listing';

//Imago Image Listing
import ImagoImageListing from '../views/Resources/Images/imago-images-listing';
// Lists
import ListListing from '../views/Resources/Lists/ListListing';
import ListCreate from '../views/Resources/Lists/ListCreate';
import ListEdit from '../views/Resources/Lists/ListEdit';
import ListContent from '../views/Resources/Lists/ListsContent/ListsContent';

// Tags
import TagListing from '../views/Resources/Tags/TagListing';
import TagCreate from '../views/Resources/Tags/TagCreate';
import TagEdit from '../views/Resources/Tags/TagEdit';

// Videos
import VideoListing from '../views/Resources/Videos/VideoListing';
import VideosCreate from '../views/Resources/Videos/VideoCreate';
import VideosEdit from '../views/Resources/Videos/VideoEdit';

// Others
import Dashboard from '../views/Pages/Dashboard/Dashboard';
import Profile from '../views/Pages/Profile/ProfileManage';

// Polls
import PollListing from '../views/Resources/polls/poll-listing';
import PollCreate from '../views/Resources/polls/poll-create';
import PollEdit from '../views/Resources/polls/poll-edit';

//Content Analytics
import { ContentAnalytics } from '../views/Resources/content-analytics/content-analytics.component';

// Feeds
import FeedListing from '../views/Resources/feeds/feed-listing';
import FeedEdit from '../views/Resources/feeds/feed-edit';
import FeedCreate from '../views/Resources/feeds/feed-create';

// Widgets embed
import WidgetsEmbedComponent from '../views/Resources/WidgetsEmbed/widgets-embed.component';

// Dugout integration
import DugoutIframePage from '../views/Resources/dugout/dugout-iframe-page';

// Onnetwork.tc integration
import OnnetworkIframePage from '../views/Resources/onnetwork/onnetwork-iframe-page';

// Football match lineups
import MatchLineupsContainer from '../views/Resources/match-lineups/match-lineups-container.component';
import MatchEdit from '../views/Resources/match-lineups/match-edit.component';
import MatchCreate from '../views/Resources/match-lineups/match-create.component';

// Football translations
import TranslationsComponent from '../views/Resources/translations/translations.component';
import { auditLogService, customBlocksService, featuresService } from '../App';
import { FeatureTypes } from '../services/feature-service/features.enum';
import PlayingSurfaceIframePage from '../views/Resources/playing-surface/playing-surface-iframe-page';

//Teams
import TeamCreate from '../views/Resources/manual-data-entries/subcomponents/teams/team-create.component';
import TeamEdit from '../views/Resources/manual-data-entries/subcomponents/teams/team-edit.component';
import TeamListing from '../views/Resources/manual-data-entries/subcomponents/teams/team-listing.component';

//Squad Players
import SquadEditComponent from '../views/Resources/manual-data-entries/subcomponents/teams/squad-edit.component';

//Players
import PlayerCreate from '../views/Resources/manual-data-entries/subcomponents/players/player-create.component';
import PlayerEdit from '../views/Resources/manual-data-entries/subcomponents/players/player-edit.component';
import PlayerListing from '../views/Resources/manual-data-entries/subcomponents/players/player-listing.component';

//Coaches
import CoachCreate from '../views/Resources/manual-data-entries/subcomponents/coaches/coach-create.component';
import CoachEdit from '../views/Resources/manual-data-entries/subcomponents/coaches/coach-edit.component';
import CoachListing from '../views/Resources/manual-data-entries/subcomponents/coaches/coaches-listing.component';

//Quick Access
import QuickAccess from '../views/Resources/QuickAccess/QuickAccessListing';
import CategoryPermissions from '../views/Resources/Categories/permissions/category-permissions.component';
import { CustomBlocksListing } from '../views/Resources/custom-blocks/custom-blocks-listing.component';
import { CustomBlockContainer } from '../views/Resources/custom-blocks/custom-block-container';
import { AuditLogPage } from '../views/Resources/audit-log/audit-log.page';
import { store } from '../store/store';
import TournamentContainer from '../views/Pages/Tournaments/TournamentContainer';

// Tournaments groups
import TournamentGroupsList from '../views/Pages/TournamentsGroups/components/TournamentGroupsList/TournamentGroupsList';
import TournamentsGroupsCreate from '../views/Pages/TournamentsGroups/components/Create/TournamentsGroupsCreate';
import TournamentsGroupEdit from '../views/Pages/TournamentsGroups/components/Edit/TournamentsGroupsEdit';

// Wiki pages
import WikiListing from '../views/Pages/Wiki/Listing/WikiListing';
import WikiEdit from '../views/Pages/Wiki/Edit/WikiEdit';
import WikiCreate from '../views/Pages/Wiki/Create/WikiCreate';

import { TopXManagerComponent } from '../views/Pages/FansUnited/topx-manager.component';
import { MatchQuizManagerComponent } from '../views/Pages/FansUnited/matchquiz-manager.component';
import { LeaderboardManagerComponent } from '../views/Pages/FansUnited/leaderboard-manager.component';
import { LiveBlogCreate } from '../views/Pages/Live Blog/components/main-components/live-blog-create.component';
import { LiveBlogEditorialAdmin } from '../views/Pages/Live Blog/components/main-components/live-blog-editorial-admin.component';
import { LiveBlogEdit } from '../views/Pages/Live Blog/components/main-components/live-blog-edit.component';
import LiveBlogListing from '../views/Pages/Live Blog/components/listing/live-blog-listing';
import JWVideos from '../views/Resources/Videos/JW-videos/JW-videos';
import GettyImagesListingContainer from '../views/v2/getty/components/listing/getty-images-listing-container';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = (t: any) => {
	return [
		// SMP routes

		// Articles
		{ path: '/dashboard', exact: true, breadcrumbs: [{ name: t('dashboard') }], component: Dashboard, enabled: true },
		{
			path: '/smp/articles',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('articles'), path: '/smp/articles' },
			],
			component: ArticleListing,
			enabled: true,
		},
		{
			path: '/smp/articles/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('articles'), path: '/smp/articles' },
				{ name: t('article_create_title') },
			],
			component: ArticleCreate,
			enabled: true,
		},
		{
			path: '/smp/articles/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('articles'), path: '/smp/articles' },
				{ name: t('article_edit_title') },
			],
			component: ArticleEdit,
			enabled: true,
		},

		// Authors
		{
			path: '/smp/authors',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('authors'), path: '/smp/authors' },
			],
			component: AuthorListing,
			enabled: true,
		},
		{
			path: '/smp/authors/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('authors'), path: '/smp/authors' },
				{ name: t('author_create_title') },
			],
			component: AuthorCreate,
			enabled: true,
		},
		{
			path: '/smp/authors/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('authors'), path: '/smp/authors' },
				{ name: t('author_edit_title') },
			],
			component: AuthorEdit,
			enabled: true,
		},

		// Banners
		{
			path: '/smp/banners',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('banners'), path: '/smp/banners' },
			],
			component: BannerListing,
			enabled: true,
		},
		{
			path: '/smp/banners/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('banners'), path: '/smp/banners' },
				{ name: t('banner_create_title') },
			],
			component: BannerCreate,
			enabled: true,
		},
		{
			path: '/smp/banners/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('banners'), path: '/smp/banners' },
				{ name: t('banner_edit_title') },
			],
			component: BannerEdit,
			enabled: true,
		},

		// Categories
		{
			path: '/smp/categories',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('categories'), path: '/smp/categories' },
			],
			component: CategoryListing,
			enabled: true,
		},
		{
			path: '/smp/categories/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('categories'), path: '/smp/categories' },
				{ name: t('category_create_title') },
			],
			component: CategoryCreate,
			enabled: true,
		},
		{
			path: '/smp/categories/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('categories'), path: '/smp/categories' },
				{ name: t('category_edit_title') },
			],
			component: CategoryEdit,
			enabled: true,
		},
		{
			path: '/smp/categories/permissions',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('categories'), path: '/smp/categories' },
				{ name: t('category_edit_permissions_title') },
			],
			component: CategoryPermissions,
			enabled: true,
		},
		// Galleries
		{
			path: '/smp/galleries',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('galleries'), path: '/smp/galleries' },
			],
			component: GalleryListing,
			enabled: true,
		},
		{
			path: '/smp/galleries/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('galleries'), path: '/smp/galleries' },
				{ name: t('gallery_create_title') },
			],
			component: GalleryCreate,
			enabled: true,
		},
		{
			path: '/smp/galleries/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('galleries'), path: '/smp/galleries' },
				{ name: t('gallery_edit_title') },
			],
			component: GalleryEdit,
			enabled: true,
		},

		// Images
		{
			path: '/smp/images',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('images'), path: '/smp/images' },
			],
			component: ImageListing,
			enabled: true,
		},

		// Playing Surface Images
		{
			path: '/smp/playingSurface',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: 'Playing Surface', path: '/smp/images/playing-surface' },
			],
			component: PlayingSurfaceImageListing,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.PLAYING_SURFACE_INTEGRATION) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		// Imago Images
		{
			path: '/smp/imagoImages',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('imago_images'), path: '/smp/images/imago-images' },
			],
			component: ImagoImageListing,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.IMAGO_INTEGRATION),
		},
		{
			path: '/smp/jw-videos',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('videos'), path: '/smp/videos/jw-videos' },
			],
			component: JWVideos,
			enabled: featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]) && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.JW_VIDEO),
		},
		// Lists
		// Editorial lists
		{
			path: '/smp/lists',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('editorial_lists'), path: '/smp/lists' },
			],
			component: ListListing,
			enabled: true,
		},
		{
			path: '/smp/lists/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('editorial_lists'), path: '/smp/lists' },
				{ name: t('editorial_list_create_title') },
			],
			component: ListCreate,
			enabled: true,
		},
		{
			path: '/smp/lists/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('editorial_lists'),
					path: '/smp/lists',
				},
				{ name: t('editorial_list_edit_title') },
			],
			component: ListEdit,
			enabled: true,
		},
		{
			path: '/smp/lists/edit-content/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('lists'), path: '/smp/lists' },
				{ name: t('list_edit_content_title') },
			],
			component: ListContent,
			enabled: true,
		},
		// Scheduled lists
		{
			path: '/smp/scheduled-lists',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('scheduled_lists'), path: '/smp/scheduled-lists' },
			],
			component: ListListing,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SCHEDULED_LISTS),
		},
		{
			path: '/smp/scheduled-lists/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('scheduled_lists'), path: '/smp/scheduled-lists' },
				{ name: t('scheduled_list_create') },
			],
			component: ListCreate,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SCHEDULED_LISTS),
		},
		{
			path: '/smp/scheduled-lists/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('scheduled_lists'), path: '/smp/scheduled-lists' },
				{ name: t('scheduled_list_edit') },
			],
			component: ListEdit,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SCHEDULED_LISTS),
		},
		{
			path: '/smp/scheduled-lists/edit-content/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('lists'), path: '/smp/lists' },
				{ name: t('list_edit_content_title') },
			],
			component: ListContent,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.SCHEDULED_LISTS),
		},
		// Tags and sports connections
		{
			path: '/smp/tags-and-sports-connections-lists',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tags_and_sports_lists'), path: '/smp/tags-and-sports-connections-lists' },
			],
			component: ListListing,
			enabled: true,
		},
		{
			path: '/smp/tags-and-sports-connections-lists/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tags_and_sports_lists'), path: '/smp/tags-and-sports-connections-lists' },
				{ name: t('tags_and_sport_connections_list_create_title') },
			],
			component: ListCreate,
			enabled: true,
		},
		{
			path: '/smp/tags-and-sports-connections-lists/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tags_and_sports_lists'), path: '/smp/tags-and-sports-connections-lists' },
				{ name: t('tags_and_sport_connections_list_edit_title') },
			],
			component: ListEdit,
			enabled: true,
		},
		{
			path: '/smp/tags-and-sports-connections-lists/edit-content/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tags_and_sports_lists'), path: '/smp/tags-and-sports-connections-lists' },
				{ name: t('tags_and_sports_lists_edit_content_title') },
			],
			component: ListContent,
			enabled: true,
		},

		// Tags
		{
			path: '/smp/tags',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tags'), path: '/smp/tags' },
			],
			component: TagListing,
			enabled: true,
		},
		{
			path: '/smp/tags/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('tags'),
					path: '/smp/tags',
				},
				{ name: t('tag_create_title') },
			],
			component: TagCreate,
			enabled: true,
		},
		{
			path: '/smp/tags/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('tags'),
					path: '/smp/tags',
				},
				{ name: t('tag_edit_title') },
			],
			component: TagEdit,
			enabled: true,
		},

		// Videos
		{
			path: '/smp/videos',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('videos'), path: '/smp/videos' },
			],
			component: VideoListing,
			enabled: true,
		},
		{
			path: '/smp/videos/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('videos'), path: '/smp/videos' },
				{ name: t('video_create_title') },
			],
			component: VideosCreate,
			enabled: true,
		},
		{
			path: '/smp/videos/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('videos'),
					path: '/smp/videos',
				},
				{ name: t('video_edit_title') },
			],
			component: VideosEdit,
			enabled: true,
		},

		// Profile
		{ path: '/dashboard', exact: true, breadcrumbs: [{ name: t('dashboard') }], component: Dashboard, enabled: true },
		{
			path: '/profile',
			exact: true,
			breadcrumbs: [{ name: t('dashboard'), path: '/dashboard' }, { name: t('profile') }],
			component: Profile,
			enabled: true,
		},

		// Polls
		// {
		// 	path: '/smp/polls',
		// 	exact: true,
		// 	breadcrumbs: [
		// 		{ name: t('dashboard'), path: '/dashboard' },
		// 		{ name: t('polls'), path: '/smp/polls' },
		// 	],
		// 	component: PollListing,
		// 	enabled: true,
		// },
		// {
		// 	path: '/smp/polls/create',
		// 	exact: true,
		// 	breadcrumbs: [
		// 		{ name: t('dashboard'), path: '/dashboard' },
		// 		{
		// 			name: t('polls'),
		// 			path: '/smp/polls',
		// 		},
		// 		{ name: t('poll_create_title') },
		// 	],
		// 	component: PollCreate,
		// 	enabled: true,
		// },
		// {
		// 	path: '/smp/polls/edit/:id',
		// 	exact: true,
		// 	breadcrumbs: [
		// 		{ name: t('dashboard'), path: '/dashboard' },
		// 		{
		// 			name: t('polls'),
		// 			path: '/smp/polls',
		// 		},
		// 		{ name: t('poll_edit_title') },
		// 	],
		// 	component: PollEdit,
		// 	enabled: true,
		// },

		// Feeds
		{
			path: '/smp/content-analytics',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('content_analytics'), path: '/smp/content_analytics' },
			],
			component: ContentAnalytics,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_ANALYTICS) && featuresService.adminIsAuthorizedForContentAnalytics(),
		},
		{
			path: '/smp/news-tracker',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('feeds'), path: '/smp/news-tracker' },
			],
			component: FeedListing,
			enabled: true,
		},
		{
			path: '/smp/news-tracker/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('feeds'), path: '/smp/news-tracker' },
				{ name: t('feed_edit_title') },
			],
			component: FeedEdit,
			enabled: true,
		},
		{
			path: '/smp/news-tracker/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('feeds'), path: '/smp/news-tracker' },
				{ name: t('feed_create_title') },
			],
			component: FeedCreate,
			enabled: true,
		},
		// Audit Log
		{
			path: '/smp/audit-log',
			exact: true,
			breadcrumbs: [{ name: t('dashboard'), path: '/dashboard' }, { name: t('audit_log') }],
			component: AuditLogPage,
			enabled: auditLogService.isFeatureEnabled(featuresService, `${store.getState().profile.profile.id}`),
		},

		// Custom Blocks
		{
			path: '/smp/custom-blocks',
			exact: true,
			breadcrumbs: [{ name: t('dashboard'), path: '/dashboard' }, { name: t('custom_blocks') }],
			component: CustomBlocksListing,
			enabled: customBlocksService.isFeatureEnabled(featuresService),
		},
		{
			path: '/smp/custom-blocks/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('custom_blocks'),
					path: '/smp/custom-blocks',
				},
				{ name: t('custom_block_edit') },
			],
			component: CustomBlockContainer,
			enabled: customBlocksService.isFeatureEnabled(featuresService),
		},
		{
			path: '/smp/custom-blocks/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('custom_blocks'),
					path: '/smp/custom-blocks',
				},
				{ name: t('custom_block_create') },
			],
			component: CustomBlockContainer,
			enabled: customBlocksService.isFeatureEnabled(featuresService),
		},

		// Widgets embed
		{
			path: '/smp/widgets-page',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('widgets_page'), path: '/smp/widgets' },
			],
			component: WidgetsEmbedComponent,
			enabled: featuresService.areFeaturesEnabled([FeatureTypes.WIDGET_EMBED, FeatureTypes.FOOTBALL]),
		},

		// Dugout website in iframe
		{
			path: '/smp/dugout',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('dugout'), path: '/smp/dugout' },
			],
			component: DugoutIframePage,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.DUGOUT_INTEGRATION),
		},

		// Onnetwork.tv website in iframe
		{
			path: '/smp/onnetwork',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('onnetwork'), path: '/smp/onnetwork' },
			],
			component: OnnetworkIframePage,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ON_NETWORK_INTEGRATION),
		},

		// Matches lineups
		{
			path: '/smp/matches',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('matches'), path: '/smp/matches' },
			],
			component: MatchLineupsContainer,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MATCH_LINEUPS),
		},

		// Match create
		{
			path: '/smp/matches/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('matches'),
					path: '/smp/matches',
				},
				{ name: t('create_match') },
			],
			component: MatchCreate,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MATCH_LINEUPS),
		},

		// Match update
		{
			path: '/smp/matches/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('matches'),
					path: '/smp/matches',
				},
				{ name: t('edit_match') },
			],
			component: MatchEdit,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MATCH_LINEUPS),
		},

		// Translations
		{
			path: '/smp/translations',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('translations'), path: '/smp/translations' },
			],
			component: TranslationsComponent,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FOOTBALL_TRANSLATION) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		// Plying Surface website in iframe
		{
			path: '/smp/playing-surface',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: 'Playing Surface', path: '/smp/playing-surface' },
			],
			component: PlayingSurfaceIframePage,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.PLAYING_SURFACE_INTEGRATION) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		// Football manual data - teams
		{
			path: '/smp/teams',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('teams'), path: '/smp/teams' },
			],
			component: TeamListing,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/teams/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('teams'),
					path: '/smp/teams',
				},
				{ name: t('team_create_title') },
			],
			component: TeamCreate,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/teams/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('teams'),
					path: '/smp/teams',
				},
				{ name: t('team_edit_title') },
			],
			component: TeamEdit,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		//Football manual data - squad
		{
			path: '/smp/squad/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{
					name: t('teams'),
					path: '/smp/teams',
				},
				{ name: t('squad_edit_title') },
			],
			component: SquadEditComponent,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		// Football manual data - players
		{
			path: '/smp/players',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('players'), path: '/smp/players' },
			],
			component: PlayerListing,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/players/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('players'), path: '/smp/players' },
				{ name: t('player_create_title') },
			],
			component: PlayerCreate,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/players/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('players'), path: '/smp/players' },
				{ name: t('player_edit_title') },
			],
			component: PlayerEdit,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_PLAYER) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		// Football manual data - coaches
		{
			path: '/smp/coaches',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('coaches'), path: '/smp/coaches' },
			],
			component: CoachListing,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_COACH) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/coaches/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('coaches'), path: '/smp/coaches' },
				{ name: t('coach_create_title') },
			],
			component: CoachCreate,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_COACH) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/coaches/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('coaches'), path: '/smp/coaches' },
				{ name: t('coach_edit_title') },
			],
			component: CoachEdit,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_COACH) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		// Football manual data - tournaments
		{
			path: '/smp/tournaments',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tournaments'), path: '/smp/tournaments' },
			],
			component: TournamentContainer,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/tournaments/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tournaments'), path: '/smp/tournaments' },
				{ name: t('new_tournament') },
			],
			component: TournamentContainer,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/tournaments/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tournaments'), path: '/smp/tournaments' },
				{ name: t('edit_tournament') },
			],
			component: TournamentContainer,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},

		// Quick Access listing
		{
			path: '/smp/quick_access/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('quick_access'), path: '/smp/quick_access/:id' },
			],
			component: QuickAccess,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.QUICK_ACCESS),
		},
		// Wiki pages
		{
			path: '/smp/wiki-pages',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('wiki_pages'), path: '/smp/wiki-pages' },
			],
			component: WikiListing,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WIKI_PAGES),
		},
		{
			path: '/smp/wiki-pages/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('wiki_pages'), path: '/smp/wiki-pages' },
				{ name: t('wiki_page_edit') },
			],
			component: WikiEdit,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WIKI_PAGES),
		},
		{
			path: '/smp/wiki-pages/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('wiki_pages'), path: '/smp/wiki-pages' },
				{ name: t('wiki_page_create') },
			],
			component: WikiCreate,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WIKI_PAGES),
		},
		// Tournament Groups Manual Data
		{
			path: '/smp/tournament-groups',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tournament_groups'), path: '/smp/tournament-groups' },
			],
			component: TournamentGroupsList,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TOURNAMENT_GROUPS) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]) &&
				featuresService.adminIsAuthorizedForTournamentGroupsOrder(),
		},
		{
			path: '/smp/tournament-groups/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tournament_groups'), path: '/smp/tournament-groups' },
				{ name: t('tournament_groups_create_title') },
			],
			component: TournamentsGroupsCreate,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/tournament-groups/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('tournament_groups'), path: '/smp/tournament-groups' },
				{ name: t('tournament_groups_edit_title') },
			],
			component: TournamentsGroupEdit,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANUAL_DATA_ENTRY_TEAM) &&
				featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL]),
		},
		{
			path: '/smp/topx-manager',
			exact: true,
			breadcrumbs: [{ name: t('dashboard'), path: '/dashboard' }, { name: 'Top X Manager' }],
			component: TopXManagerComponent,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && featuresService.checkIfFUManagerIsEnabled('top-x'),
		},
		{
			path: '/smp/match-quiz-manager',
			exact: true,
			breadcrumbs: [{ name: t('dashboard'), path: '/dashboard' }, { name: 'Match Quiz Manager' }],
			component: MatchQuizManagerComponent,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && featuresService.checkIfFUManagerIsEnabled('match-quiz'),
		},
		{
			path: '/smp/leaderboard-manager',
			exact: true,
			breadcrumbs: [{ name: t('dashboard'), path: '/dashboard' }, { name: 'Leaderboard Manager' }],
			component: LeaderboardManagerComponent,
			enabled:
				featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && featuresService.checkIfFUManagerIsEnabled('leaderboard'),
		},
		// Wiki pages
		{
			path: '/smp/live-blogs',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('live_blogs'), path: '/smp/live-blogs' },
			],
			component: LiveBlogListing,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIVE_BLOG),
		},
		{
			path: '/smp/live-blogs/configuration/create',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('live_blogs'), path: '/smp/live-blogs' },
				{ name: t('live_blogs_configuration') },
			],
			component: LiveBlogCreate,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIVE_BLOG),
		},
		{
			path: '/smp/live-blogs/configuration/edit/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('live_blogs'), path: '/smp/live-blogs' },
				{ name: t('live_blogs_configuration') },
			],
			component: LiveBlogEdit,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIVE_BLOG),
		},
		{
			path: '/smp/live-blogs/editorial-admin/:id',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('live_blogs'), path: '/smp/live-blogs' },
				{ name: t('live_blogs_editorial_admin') },
			],
			component: LiveBlogEditorialAdmin,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.LIVE_BLOG),
		},

		// Getty Images
		{
			path: '/smp/getty-images',
			exact: true,
			breadcrumbs: [
				{ name: t('dashboard'), path: '/dashboard' },
				{ name: t('getty_images'), path: '/smp/images/getty-images' },
			],
			component: GettyImagesListingContainer,
			enabled: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.GETTY_IMAGES),
		},
	];
};

export default routes;
