import { SportTypes } from '../constants/sport.types';

export const getSportIcon = (sport: SportTypes): string => {
	switch (sport) {
		case SportTypes.FOOTBALL:
			return require('./../assets/icons/sports/football.svg');
		case SportTypes.BASKETBALL:
			return require('./../assets/icons/sports/basketball.svg');
		case SportTypes.TENNIS:
			return require('./../assets/icons/sports/tennis.svg');
		case SportTypes.CYCLING:
			return require('./../assets/icons/sports/cycling.svg');
		case SportTypes.HANDBALL:
			return require('./../assets/icons/sports/handball.svg');
		case SportTypes.ICE_HOCKEY:
			return require('./../assets/icons/sports/ice-hockey.svg');
		case SportTypes.MOTORSPORTS:
			return require('./../assets/icons/sports/motorsports.svg');
		default:
			return '';
	}
};
