import React from 'react';
import GettyImageModel from '../../models/getty-image.model';
import { noPreviewImage } from '../../../../../constants/resource-constants';
import DangerouslySetInner from '../../../../Partials/social-feed-selection-sidebar/subcomponents/dangerously-set-inner.component';
import { retrieveCorrectImageDisplaySize } from '../../helpers/getty-images.helper';

type Properties = {
	image: GettyImageModel;
};

const GettyImagesView: React.FunctionComponent<Properties> = ({ image }) => {
	if (image) {
		const previewImage =
			image.displaySizes && image.displaySizes.length > 0 ? retrieveCorrectImageDisplaySize(image.displaySizes) : noPreviewImage;

		return (
			<div data-qa='getty-images-block-view'>
				<div className='block-content'>
					<div className={`d-flex content-container`}>
						{<img className='content-image img-fluid mb-2 mb-md-0 rounded' alt={image.caption ? image.caption : ''} src={previewImage} />}
						{image.caption && (
							<div className='gallery-title text-left'>
								<h6 className='m-0 p-0 col-lg-8 col-md-7 col-sm-6'>
									<DangerouslySetInner html={image.caption} />
								</h6>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='position-relative text-center'>
			<img alt='' src={noPreviewImage} className='no-preview-image img-fluid mb-2 mb-md-0 rounded' />
		</div>
	);
};

export default GettyImagesView;
