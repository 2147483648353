import { store } from '../store/store';

enum CommonSubdomains {
	INTEGRATION = '.integration',
	STAGING = '.staging',
	SANDBOX = '.sandbox',
}

export enum Environments {
	INTEGRATION = 'integration',
	STAGING = 'staging',
	SANDBOX = 'sandbox',
	PRODUCTION = 'production',
}

const REACT_APP_URLS = (function () {
	const hostname: string = window.location.hostname;
	let projectSubdomain = ''; // By default - equivalent to "cms.sportal365.com" hostname
	let REACT_APP_FOOTBALL_API_FOOTBALL_API_AUTH_HEADER =
		'Basic ZTVjMjA2ZDExZDQ5ZDMxM2U2MGJmNGQ2NWQxNjc3YzU6ZDJkOTkxNzVkMTgwNzM4NjZhZThkMzQyNjU0ZTJkMzc=';
	let REACT_APP_ENV: Environments = Environments.PRODUCTION;

	if (hostname === 'localhost') {
		projectSubdomain = CommonSubdomains.INTEGRATION;
	} else {
		const projectSubdomainRegex = /cms([.\w]+)\.sportal365\.com/;
		const regexExecResult = projectSubdomainRegex.exec(hostname);

		if (regexExecResult) {
			projectSubdomain = regexExecResult[1];
		}
	}

	if (projectSubdomain === CommonSubdomains.INTEGRATION) {
		// Integration-specific settings
		REACT_APP_FOOTBALL_API_FOOTBALL_API_AUTH_HEADER =
			'Basic N2U2NmIzNjY3NjA0YmQ0YThmZTJjYTcwOTkwYTNkNDQ6MzUyNTA4YWM0NzU5OTg0MDE5YzY4MTE1YzliMTcwMWI=';
		REACT_APP_ENV = Environments.INTEGRATION;
	} else if (projectSubdomain === CommonSubdomains.STAGING) {
		// Staging-specific settings
		REACT_APP_FOOTBALL_API_FOOTBALL_API_AUTH_HEADER =
			'Basic YjNiN2U2ZjVjZDg3ZDk0ODNjZjZkMGM2YjVkOWY0YzU6NzBkZDQ2MTc1ZWMwNmJlZjdjZDZlZDUwYTJiNzg2M2I=';
		REACT_APP_ENV = Environments.STAGING;
	} else if (projectSubdomain === CommonSubdomains.SANDBOX) {
		// Sandbox-specific settings
		REACT_APP_ENV = Environments.SANDBOX;
	}

	return {
		PORT: 3000,
		CHOKIDAR_USEPOLLING: true,
		REACT_APP_OAUTH_CLIENT_ID: 100,
		REACT_APP_OAUTH_CLIENT_SECRET: 'JI6LgFTJrBJZ1snMzMIXAHMAC71DpeyvEvis6UHJ',
		REACT_APP_OAUTH_GRANT_TYPE: 'password',
		REACT_APP_CONTENT_API_BASE_URL: `https://content.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_CLIENT_API_BASE_URL: `https://client.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_FOOTBALL_API_FOOTBALL_API_AUTH_HEADER,
		REACT_APP_IMAGE_API_BASE_URL: `https://image.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_NEWS_FEED_URL: `https://newsfeed.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_AUTO_TAGGING_URL: `https://autotagging.api${projectSubdomain}.sportal365.com`,
		REACT_APP_AUTO_TAGGING_V2_API_AUTH_HEADER: 'Basic YXV0b3RhZ2dpbmc6YTYyVkxKYkFHNTA3UVBBUQ==',
		REACT_APP_STATISTICS_API_BASE_URL: `https://statistic.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_WIDGETS_CDN_URL: 'https://widgets.sportal365.com',
		REACT_APP_IMAGO_PROXY_URL: 'https://imago.proxy.sportal365.com',
		REACT_APP_CONTENT_ANALYTICS_BASE_URL: `https://content-statistics.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_CUSTOMIZATION_API_BASE_URL: `https://customization.api${projectSubdomain}.sportal365.com`,
		REACT_APP_CUSTOMIZATION_API_AUTH_HEADER: 'Basic Y3VzdG9taXphdGlvbmFwaTpOYWEzY1dNVHZtdnZ6d2Y3UVUzNw==',
		REACT_APP_SEARCH_API_BASE_URL: `https://sports-search.api${projectSubdomain}.sportal365.com/`,
		REACT_APP_BASKETBALL_API_URL: `https://basketball.api${projectSubdomain}.sportal365.com`,
		REACT_APP_ODDS_API_URL: `https://odds.api${projectSubdomain}.sportal365.com`,
		REACT_APP_WIDGETS_LOADER_API_URL: 'https://widgets.sportal365.com/loader.bundle.min.js',
		REACT_APP_TENNIS_API_URL: `https://tennis.api${projectSubdomain}.sportal365.com`,
		REACT_APP_WIDGETS_MONOREPO_URL: `https://storybook.widgets${projectSubdomain}.sportal365.com/`,
		REACT_APP_HOCUS_POCUS_WEB_SOCKET_URL: [Environments.INTEGRATION, Environments.STAGING].includes(REACT_APP_ENV)
			? `wss://collaboration-api.api${projectSubdomain}.sportal365.com/ws:443`
			: `wss://collaboration.api${projectSubdomain}.sportal365.com/ws:443`,
		REACT_APP_ENV,
		REACT_APP_STANDING_API_URL: `https://standing.api${projectSubdomain}.sportal365.com`,
	};
})();

window['getReactAppUrls'] = () => {
	const state = store.getState();

	if (!state.profile.profile.id) {
		return 'Please log in to access REACT_APP_URLS.';
	}

	return REACT_APP_URLS;
};

export default REACT_APP_URLS;
