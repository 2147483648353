import React, { ComponentType } from 'react';
import GettyImageModel from '../../models/getty-image.model';
import BlockModel from '../../../../Partials/Blocky/models/block.model';
import { blockManagementService, blockWrapperService } from '../../../../Partials/Blocky/subcomponents/blocky.component';
import BlockValidation from '../../../../Partials/Blocky/helpers/block-validation.helper';
import GettyApiService from '../../helpers/getty-api.service';
import { GettyImageContainer } from '../partials/getty-image-container.component';
import { withTranslation } from 'react-i18next';

export type Properties = {
	block: BlockModel;
};

export type State = {
	selectedGettyImage: GettyImageModel;
	isValid: boolean;
	searchText: string;
	searchIn: string;
	pageOffset: number;
	selectedImageType: string;
	gettyInstance: GettyApiService;
};

class GettyImagesEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			selectedGettyImage: props.block && props.block.data && props.block.data.id ? props.block.data.preview.gettyImage : new GettyImageModel(),
			isValid: true,
			searchText: '',
			searchIn: '',
			pageOffset: props.block && props.block.data && props.block.data.preview ? props.block.data.preview.pageOffset : 1,
			selectedImageType: '',
			gettyInstance: new GettyApiService(),
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const { selectedGettyImage, searchIn, pageOffset, selectedImageType } = this.state;
		const data = { gettyImage: selectedGettyImage };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) === true;
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = selectedGettyImage.id;
			block.data.content = selectedGettyImage;
			block.data.preview = {
				gettyImage: selectedGettyImage,
				searchInput: searchIn,
				pageOffset: pageOffset,
				selectedImageType: selectedImageType,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	selectGettyImage = (image: GettyImageModel) => {
		this.setState({ ...this.state, selectedGettyImage: image });
	};

	updateSearchInput = (search: string, pageOffset: number, imageType: string) => {
		this.setState({ ...this.state, searchText: search, searchIn: search, pageOffset: pageOffset, selectedImageType: imageType });
	};

	render() {
		const { block } = this.props;
		const { pageOffset, selectedGettyImage, isValid, gettyInstance } = this.state;

		return (
			<div className={'mb-2'}>
				<GettyImageContainer
					onSelect={this.selectGettyImage}
					block={block}
					updateSearchInput={this.updateSearchInput}
					isValid={isValid}
					selectedImage={selectedGettyImage}
					inBlocky
					pageOffset={pageOffset}
					gettyInstance={gettyInstance}
				/>
			</div>
		);
	}
}

export default withTranslation()(GettyImagesEdit) as ComponentType<Properties>;
